.download-button {
    color: var(--color-panna);
    font-family: FashionFetish;
    line-height: 25px;
}

.download-button:hover {
    color: var(--color-rosino);

}
