.home-shows-container {
    padding: 10% 0;
}

@media screen and (max-width: 768px) {
    .home-shows-container {
        align-items: center;
        display: flex;
        min-height: 600px;
        padding: 10% 0 5%;
    }
}
