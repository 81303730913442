/* GENERAL CSS RULES */
:root {
    --font-family: "adobe-caslon-pro", serif;
    --font-style: normal;
    --color-vinaccia: #5E0928;
    --color-rosino: #B7556C;
    --color-panna: #E4D6A3;
    --color-bg: var(--color-vinaccia);
    --color-text: var(--color-panna);
    --color-subtext: var(--color-panna);
}

@font-face {
    font-family: 'FashionFetish';
    src:  url('./assets/fonts/Fashion-Fetish-Regular.ttf') format('truetype'), url('./assets/fonts/Fashion-Fetish-Regular.woff2') format('woff');
    font-display: swap;
    font-style: normal;
    font-weight: normal;
}
  
@font-face {
    font-family: 'FashionFetish';
    src:  url('./assets/fonts/Fashion-Fetish-Bold.ttf') format('truetype'), url('./assets/fonts/Fashion-Fetish-Bold.woff2') format('woff');
    font-display: swap;
    font-style: normal;
    font-weight: bold;
}
  
@font-face {
    font-family: 'FashionFetish';
    src: url('./assets/fonts/Fashion-Fetish-Bold.ttf') format('truetype'), url('./assets/fonts/Fashion-Fetish-Bold.woff2') format('woff');
    font-display: swap;
    font-style: normal;
    font-weight: lighter;
}
  
h1 {
    color: var(--color-text);
    font-family: var(--font-family);
    font-size: 45px;
    font-weight: 600;
    line-height: 50px;
    text-transform: uppercase;
}
  
h2 {
    color: var(--color-text);
    font-family: var(--font-family);
    font-size: 35px;
    font-weight: 600;
    line-height: 40px;
    margin: 0 0 2rem;
    text-transform: uppercase;
}

h3 {
    color: var(--color-text);
    font-family: FashionFetish;
    font-size: 25px;
    font-weight: 600;
    line-height: 35px;
    margin: 0 0 1rem;
}
  
p {
    color: var(--color-subtext);
    font-family: FashionFetish;
    font-size: 16px;
    line-height: 25px;
}
  
a {
    color: var(--color-subtext);
    font-family: FashionFetish;
    line-height: 25px;
}

button, .button-link {
    align-items: center;
    background-color: var(--color-rosino);
    border: 1px solid var(--color-rosino);
    border-radius: 5px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    margin-top: 2rem;
    min-height: 50px;
    min-width: 100px;
    padding: 10px 15px;
    text-decoration: none;
    text-transform: uppercase;
}

button a {
    text-decoration: none;
}

button:hover, .button-link:hover {
    background-color: var(--color-panna);
    border: 1px solid var(--color-panna);
    color: var(--color-rosino);
}

button:hover a {
    color: var(--color-rosino);
}

ul {
    color: var(--color-panna);
    padding-left: 20px;
}

@media screen and (max-width: 700px) {
    h1 {
      font-size: 35px;
      line-height: 40px;
    }
  
    h2 {
      font-size: 30px;
      line-height: 35px;
      margin: 0 0 1rem;
    }
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg);
}

.container {
    margin: 0 auto;
    max-width: 1200px;
}

.section__padding {
    padding: 4rem 2rem;
}

.section__margin {
    margin: 4rem 2rem;
}

.text-align-center {
    text-align: center;
}

@media screen and (max-width: 767px) {
    .section__padding {
        padding: 2rem;
    }

    .secion__margin {
        margin: 2rem;
    }
}


/* COMMON ANIMATION CLASSES */
  
/* START - Menu scale up */
  
.scale-up-center {
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
  
@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
  
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
  
@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
  
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
  
/* END - Menu scale up */
  
/* START - Slide in */
.slide-in-fwd-center {
    -webkit-animation: slide-in-fwd-center 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-fwd-center 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
  
@-webkit-keyframes slide-in-fwd-center {
    0% {
      -webkit-transform: translateZ(-1400px);
      transform: translateZ(-1400px);
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
}
  
@keyframes slide-in-fwd-center {
    0% {
      -webkit-transform: translateZ(-1400px);
      transform: translateZ(-1400px);
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
}
  
/* END - Slide in */
