.footer .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.footer .footer-content .column {
    width: calc(100%/3);
}

.footer .footer-content .column p {
    font-size: 14px;
}

.footer .footer-content .column.column-1 a {
    display: block;
}

.footer .footer-content .column.column-1 a.mail {
    font-size: 14px;
    margin-top: 5px;
}

.footer .footer-content .column.column-3 a {
    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 5px;
}

.footer .footer-content .column.column-3 img {
    height: auto;
    margin-right: 5px;
}

.footer .copyright {
    font-size: 11px;
    line-height: 13px;
    padding: 15px;
    text-align: center;
}

@media screen and (max-width: 700px) {
    .footer .footer-content .column {
        margin: 10px 0;
        width: 100%;
    }

    .footer .copyright {
        font-size: 10px;
        padding: 10px;
    }
}
