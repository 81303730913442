.navbar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 3rem 2rem;
}

.navbar-links {
    flex: 1;
    display: flex;
    align-items: center;
}

.navbar-links_logo {
    margin-right: auto;
}

.navbar-links_logo a {
    display: flex;
}

.navbar-links_container {
    display: flex;
    flex-direction: row;
}

.navbar-links_container a,
.navbar-menu_container a {
    margin: 0 1rem;
    text-decoration: none;
}

.navbar-links_container a.active-menu {
    color: var(--color-rosino);
    text-decoration: underline;
}

.navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.navbar-menu svg {
    color: var(--color-panna) !important;
    cursor: pointer;
}

.navbar-menu_container {
    align-items: flex-end;
    background: var(--color-bg);
    border: 2px solid var(--color-rosino);
    border-radius: 10px;
    box-shadow: 0 0 5 rgba(0, 0, 0.2);display: flex;
    justify-content: flex-end;
    flex-direction: column;
    min-width: 210px;
    padding: 1rem 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    text-align: end;
    z-index: 99;
}

.navbar-menu_container a {
    display: block;
    margin: 1rem 0;
}

.navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1200px) {
    .navbar {
        padding: 2rem;
    }

    .navbar-links_container {
        display: none;
    }

    .navbar-menu {
        display: flex;
    }
}
